const Mock = require('mockjs');
export default [
    // 站点信息
    {
        url: '/site',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: {
                    avatar: "",
                    slogan: '粤容易博客分享，学习越容易',
                    name: 'YRY',
                    domain: 'https://www.yuerongyi.top',
                    notice: '本博客的数据由Mockjs生成,静态数据展示',
                    desc: '技术学习OR博客搭建'
                }
            }
        }
    },
    // 站点社交信息
    {
        url: '/social',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: [
                    // {
                    //     id: 1,
                    //     title: 'QQ',
                    //     icon: 'iconqq',
                    //     color: '#1AB6FF ',
                    //     href: 'http://wpa.qq.com/msgrd?v=3&uin=1224971566&site=qq&menu=yes'
                    // },
                    // {
                    //     id: 2,
                    //     title: 'Gitee',
                    //     icon: 'icongitee',
                    //     color: '#d81e06',
                    //     href: 'https://gitee.com/fengziy'
                    // },
                    // {
                    //     id: 3,
                    //     title: 'GitHub',
                    //     icon: 'icongithub',
                    //     color: '',
                    //     href: 'https://github.com/fengziye'
                    // },
                    // {
                    //     id: 4,
                    //     title: 'CSDN',
                    //     icon: 'iconcsdn',
                    //     color: 'red',
                    //     href: 'https://blog.csdn.net/feng_zi_ye'
                    // }
                ]
            }
        }
    }
]