<template>
    <div id="app" v-cloak>
        <loading></loading>
        <layout-header></layout-header>
        <layout-body></layout-body>
        <layout-footer></layout-footer>
    </div>
</template>
<script>
    import layoutHeader from '@/components/layout/layout-header'
    import layoutBody from '@/components/layout/layout-body'
    import layoutFooter from '@/components/layout/layout-footer'
    import Loading from '@/components/loading'
    export default {
        name: "app",
        components: {
            layoutHeader,
            layoutBody,
            layoutFooter,
            Loading
        },
        mounted(){
            // setTimeout(()=>{
            //     let screenWidth = window.screen.width;
            //     let innerWidth = window.innerWidth;
            //     let screenHeight = window.screen.height;
            //     let innerHeight = window.innerHeight;
            //     //如果窗口外部高度-内部高度大于200，或者外部宽度-内部宽度大于200，就跳转到about:blank
            //     if (screenWidth - innerHeight > 200 || screenHeight - innerWidth > 200) {
            //     window.location.href = 'about:blank';
            //     }
            // },200)
             //监听用户敲击键盘事件
            window.addEventListener('keydown',this.handleKeydown);
            //阻止右键打开菜单
            window.oncontextmenu = function(event) {
                event.preventDefault();
            };
            window.addEventListener('resize',()=>{
                 //禁止鼠标右击
                window.oncontextmenu = function(event) {
                    event.returnValue = false;
                };
                //禁用开发者工具F12
                window.addEventListener('keydown',this.handleKeydown);
                // let userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") > -1) {
                    let checkStatus;
                    let devtools = /./;
                    devtools.toString = function() {
                    checkStatus = "on";
                    };
                    setInterval(function() {
                    checkStatus = "off";
                    console.log(devtools);
                    console.log(checkStatus);
                    console.clear();
                    if (checkStatus === "on") {
                        let target = "";
                        try {
                        window.open("about:blank", (target = "_self"));
                        } catch (err) {
                        let a = document.createElement("button");
                        a.onclick = function() {
                            window.open("about:blank", (target = "_self"));
                        };
                        a.click();
                        }
                    }
                    }, 200);
                } else {
                    //禁用控制台
                    let ConsoleManager = {
                        onOpen: function() {
                            alert("Console is opened");
                        },
                        onClose: function() {
                            alert("Console is closed");
                        },
                        init: function() {
                            let self = this;
                            let x = document.createElement("div");
                            let isOpening = false,
                            isOpened = false;
                            Object.defineProperty(x, "id", {
                            get: function() {
                                if (!isOpening) {
                                self.onOpen();
                                isOpening = true;
                                }
                                isOpened = true;
                                return true;
                            }
                            });
                            setInterval(function() {
                            isOpened = false;
                            console.info(x);
                            console.clear();
                            if (!isOpened && isOpening) {
                                self.onClose();
                                isOpening = false;
                            }
                            }, 200);
                        }
                    };
                    ConsoleManager.onOpen = function() {
                        //打开控制台，跳转
                        let target = "";
                        try {
                            window.open("about:blank", (target = "_self"));
                        } catch (err) {
                            let a = document.createElement("button");
                            a.onclick = function() {
                            window.open("about:blank", (target = "_self"));
                            };
                            a.click();
                        }
                    };
                    ConsoleManager.onClose = function() {
                        alert("Console is closed!!!!!");
                    };
                    ConsoleManager.init();
                }
            });
        },
        methods:{
            handleKeydown(event) {
            //如果键盘敲击f12就阻止f12打开，然后弹框提醒，这个都放在app.vue里面，当然在页面还没有加载的时候最好在index.html里面也加上对应的方法
                if (event.key === 'F12') {
                    event.preventDefault(); // 阻止F12的默认行为
                    window.location.href = 'about:blank';
                    Modal.error({
                        title:'警告',
                        content:'检测非法调试，F12被管理员禁用',
                        okText:'知道了'
                    })
                }
            }
        },

    }
</script>
<style lang="less">
    #app {
        font-size: 17px;
        color: #6f6f6f;
    }
</style>
